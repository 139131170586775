import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/services/api'

Vue.use(Vuex)

// 認証情報
const authModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    isLoggedIn: false,
    full_name: ''
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    full_name: state => state.full_name,
  },
  mutations: {
    set(state, payload) {
      state.isLoggedIn = true
      state.full_name = payload.user.full_name
    },
    clear(state) {
      state.isLoggedIn = false
      state.full_name = ''
    }
  },
  actions: {
    /**
     * ログイン
     */
    login(context, payload) {
      var host = process.env.VUE_APP_API_BASE_URL;
      return api.post(host + '/auth/token/login/', {
        'username': payload.username,
        'password': payload.password
      })
        .then(response => {
          // 認証用トークンをsessionStorageに保存
          sessionStorage.setItem('token', response.data.auth_token)
          // ユーザー情報を取得してstoreのユーザー情報を更新
          return context.dispatch('reload')
            .then(user => user)
        })
    },
    /**
     * ログアウト
     */
    logout(context) {
      // 認証用トークンをsessionStorageから削除
      sessionStorage.removeItem('token')
      // ユーザ情報をsessionStorageから削除
      sessionStorage.removeItem('user')
      // storeのユーザー情報をクリア
      context.commit('clear')
    },
    /**
     * ユーザー情報更新
     */
    reload(context) {
      var host = process.env.VUE_APP_API_BASE_URL;
      return api.get(host + '/auth/users/me/')
        .then(response => {
          const user = response.data
          // storeのユーザー情報を更新
          context.commit('set', { user: user })
          //sessionStrageにユーザ情報を設定
          sessionStorage.setItem('user', JSON.stringify(user))
          return user
        })
    }
  }
}

// グローバルメッセージ
const messageModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    error: '',
    warnings: [],
    info: ''
  },
  getters: {
    error: state => state.error,
    warnings: state => state.warnings,
    info: state => state.info
  },
  mutations: {
    set(state, payload) {
      if (payload.error) {
        state.error = payload.error
      }
      if (payload.warnings) {
        state.warnings = payload.warnings
      }
      if (payload.info) {
        state.info = payload.info
      }
    },
    clear(state) {
      state.error = ''
      state.warnings = []
      state.info = ''
    }
  },
  actions: {
    /**
     * エラーメッセージ表示
     */
    setErrorMessage(context, payload) {
      context.commit('clear')
      context.commit('set', { 'error': payload.message })
    },
    /**
     * 警告メッセージ（複数）表示
     */
    setWarningMessages(context, payload) {
      context.commit('clear')
      context.commit('set', { 'warnings': payload.messages })
    },
    /**
     * インフォメーションメッセージ表示
     */
    setInfoMessage(context, payload) {
      context.commit('clear')
      context.commit('set', { 'info': payload.message })
    },
    /**
     * 全メッセージ削除
     */
    clearMessages(context) {
      context.commit('clear')
    }
  }
}

const store = new Vuex.Store({
  modules: {
    auth: authModule,
    message: messageModule
  }
})

export default store
<template>
  <v-app>
    <GlobalHeader />
    <GlobalMessage />
    <v-card>
      <v-card-title>
        勤務者一覧
        <v-spacer></v-spacer>
        <v-select
          v-model="targetYM"
          :items="ymList"
          item-text="ym"
          item-value="ym"
          label="対象年月"
          prepend-icon="mdi-calendar-question"
          @change="loadItem()"
        />
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="検索"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="itemlist"
        class="elevation-1 ma-5"
        :items-per-page="5"
        locale="ja-JP"
        :search="search"
      >
        <template v-slot:[`item.fullname`]="{ item }">
          <a :href="'/workingstatus/'+item.user+'/'+targetYM" target="_blank" rel="opener">{{item.fullname}}</a>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import api from "@/services/api";
import moment from "moment";
// const JapaneseHolidays = require("japanese-holidays");

export default {
  components: {
    GlobalHeader,
    GlobalMessage
  },
  data: () => ({
    host: null,
    targetYM: null,
    ymList: null,
    itemlist: [],
    headers: [
      { text: "ユーザID", value: "user" },
      { text: "氏名", value: "fullname" },
      { text: "JOBID", value: "job" },
      { text: "ジョブ名", value: "jobname" }
    ],
    search: null
  }),
  created() {
    this.host = process.env.VUE_APP_API_BASE_URL;
    this.ymList = this.getYmList();
    if (!this.targetYM) {
      this.targetYM = moment().format("YYYY-MM");
    }
    this.loadItem();
  },
  methods: {
    getYmList() {
      const toDay = moment().format("YYYY-MM-DD");
      let tmpToday = moment(toDay).add(1, "M");
      let rtn = [];
      for (let i = 0; i < 12; i++) {
        rtn.push({ ym: tmpToday.subtract(1, "M").format("YYYY-MM") });
      }
      return rtn;
    },
    loadItem() {
      api.get(this.host + "/api/userjob/" + this.targetYM).then(response => {
        this.itemlist = response.data;
      });
    }
  }
};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/pages/HomePage'
import LoginPage from '@/pages/LoginPage'
import CreatePage from '@/pages/CreatePage'
import ItemPage from '@/pages/ItemPage'
import WorkingStatus from '@/pages/WorkingStatus'
import ChangePassword from '@/pages/ChangePassword'
import ApproveList from '@/pages/ApproveList'
import SearchStaff from '@/pages/SearchStaff'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // ログインが必要な画面には「requiresAuth」フラグを付けておく
  routes: [{
    path: '/',
    component: HomePage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/create',
    component: CreatePage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/item/:uuid',
    component: ItemPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/changepassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workingstatus/:user/:month',
    component: WorkingStatus,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/approvelist',
    component: ApproveList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/searchstaff',
    component: SearchStaff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
  ]
})

/**
 * Routerによって画面遷移する際に毎回実行される
 */
router.beforeEach((to, from, next) => {

  const isLoggedIn = store.getters['auth/isLoggedIn']
  const token = sessionStorage.getItem('token')

  // ログインが必要な画面に遷移しようとした場合
  if (to.matched.some(record => record.meta.requiresAuth)) {

    // ログインしている状態の場合
    if (isLoggedIn) {
      next()

      // ログインしていない状態の場合
    } else {
      // まだ認証用トークンが残っていればユーザー情報を再取得
      if (token != null) {

        store.dispatch('auth/reload')
          .then(() => {
            // 再取得できたらそのまま次へ
            next()
          })
          .catch(() => {
            // 再取得できなければログイン画面へ
            forceToLoginPage(to, from, next)
          })
      } else {
        // 認証用トークンが無い場合は、ログイン画面へ
        forceToLoginPage(to, from, next)
      }
    }

  } else {
    // ログインが不要な画面であればそのまま次へ
    next()
  }
})

/**
 * ログイン画面へ強制送還
 */
function forceToLoginPage(to, from, next) {
  next({
    path: '/login',
    // 遷移先のURLはクエリ文字列として付加
    query: {
      next: to.fullPath
    }
  })
}

export default router
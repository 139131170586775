<template>
  <v-app>
    <GlobalHeader />
    <GlobalMessage />
    <template v-if="(usertype=='EM')">
      <v-card>
        <v-card-title>営業担当者メニュー</v-card-title>
      </v-card>
      <v-card>
        <v-select
          v-model="targetYM"
          :items="ymList"
          item-text="ym"
          item-value="ym"
          label="対象年月"
          prepend-icon="mdi-select-group"
          @change="getUserList(comcode,targetYM)"
        />
      </v-card>
      <v-card
        tile
        v-for="item in userList"
        :key="item.username"
        class="mx-0 mt-0"
        :to="'/workingstatus/'+item.user.username+'/'+targetYM"
      >
        <v-card-title class="subtitle-2 mx-0 my-0 px-2 py-2">
          <v-icon color="white">mdi-file-document-box-check-outline</v-icon>
          {{item.user.full_name}}
        </v-card-title>
      </v-card>
    </template>
    <template v-else>
      <v-card v-if="userinfo.is_staff">
        <v-card-title>管理者メニュー</v-card-title>
        <v-card-text>
          <ul>
            <li>
              <a href="/approvelist">承認一覧</a>
            </li>
            <li>
              <a href="/searchstaff">勤務表検索</a>
            </li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card flat tile v-if="(usertype!='P')" class="px-2">
        <v-card-title>{{this.toDay}}の勤怠</v-card-title>
        <v-card-text>
          <v-form ref="inputform">
            <v-text-field
              v-model="login_user"
              label="ログインユーザ"
              prepend-icon="mdi-account-box"
              disabled
            />
            <v-select
              v-model="joined_job"
              :items="job_data"
              item-text="job_name"
              item-value="job_id"
              label="ジョブ"
              prepend-icon="mdi-select-group"
              :rules="[required]"
              return-object
            />
            <div id="submit">
              <v-card-actions>
                <v-btn v-on:click="punchIn" dark class="mx-auto">出勤</v-btn>
                <v-dialog v-model="outdialog" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-btn dark class="mx-auto" v-on="on">退勤</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">休憩時間入力</v-card-title>
                    <v-form ref="breakform">
                      <v-card-text>
                        <v-text-field
                          label="休憩時間（分単位）"
                          v-model="breaktime"
                          :rules="[isNumberField]"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="green darken-1" text @click="outdialog = false;punchOut();">送信</v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
                <v-btn
                  dark
                  class="mx-auto"
                  :to="'/workingstatus/'+userinfo.username+'/'+targetYM"
                >勤務一覧</v-btn>
              </v-card-actions>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card flat tile dark color="deep-purple " class="px-2">
        <v-card-title class="subtitle mx-0 my-0 px-2 py-2">
          <v-icon>mdi-currency-jpy</v-icon>経費情報
          <v-btn icon :to="'/workingstatus/'+userinfo.username+'/'+targetYM">
            <v-icon>mdi-table</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-data-iterator :items="items" :search="search" hide-default-footer items-per-page=9999>
        <template v-slot:header>
          <v-toolbar
            flat 
            tile 
            dark 
            color="deep-purple"
            class="px-2"
          >
            <v-text-field
              v-model="search"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:default="props">
          <v-card
            outlined
            tile
            v-for="item in props.items"
            :key="item.id"
            class="mx-0 mt-0"
            :to="'/item/'+item.id"
            :color="cardColor(item.status_disp)"
          >
            <v-card-title class="subtitle-2 mx-0 my-0 px-2 py-2 white--text">
              <v-icon color="white">mdi-file-document-box-check-outline</v-icon>
              {{item.title}}
            </v-card-title>
            <v-card-text class="mt-0 mb-0 mx-0 pl-2 py-0">
              <v-chip outlined label class="mr-2 mb-2" color="white">{{item.at_expense}}</v-chip>
              <v-chip outlined label class="mr-2 mb-2" color="white">{{item.status_disp}}</v-chip>
              <v-chip outlined label class="mr-2 mb-2" color="white">{{item.type_disp}}</v-chip>
              <v-chip outlined label class="mr-2 mb-2" color="white">{{item.amount}}円</v-chip>
            </v-card-text>
            <v-divider dark />
          </v-card>
        </template>
      </v-data-iterator>
    </template>
  </v-app>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import api from "@/services/api";
import moment from "moment";
export default {
  components: {
    GlobalHeader,
    GlobalMessage
  },
  data: () => ({
    search:'',
    items: [],
    userinfo: null,
    usertype: null,
    toDay: null,
    job_data: null,
    login_user: null,
    host: null,
    userList: null,
    targetYM: null,
    ymList: null,
    comcode: null,
    outdialog: false,
    joined_job: null,
    breaktime: 0,
    isNumberField: value => isFinite(value) || "数値で入力してください",
    required: value => !!value || "必ず入力してください"
  }),

  created() {
    // var moment = require("moment-timezone");
    this.host = process.env.VUE_APP_API_BASE_URL;
    this.userinfo = JSON.parse(sessionStorage.getItem("user"));
    this.usertype = this.userinfo.username.charAt(0);
    this.ymList = this.getYmList();
    this.comcode = this.userinfo.username.substr(0, 2);
    if (this.userinfo.username.substr(-3) == "000") {
      this.usertype = "EM";
    }
    this.toDay = moment().format("YYYY-MM-DD");
    if (this.targetYM == null) {
      this.targetYM = moment().format("YYYY-MM");
    }
    if (this.usertype != "EM") {
      this.getJobList(this.userinfo.username, this.targetYM);
      this.login_user = this.userinfo["full_name"];
      api.get(this.host + "/api/expenses/").then(response => {
        if (response.data.length > 0) {
          this.items = response.data;
        }
      });
    } else {
      this.getUserList(this.comcode, this.targetYM);
    }
  },
  computed: {
    kihyo: () => "light-blue lighten-5"
  },
  methods: {
    getJobList(user, targetYM) {
      api
        .get(this.host + "/api/joinedjob/" + user + "/" + targetYM)
        .then(response => {
          this.job_data = response.data;
        });
    },
    getYmList() {
      const moment = require("moment");
      const toDay = moment().format("YYYY-MM-DD");
      let tmpToday = moment(toDay).add(1, "M");
      let rtn = [];
      for (let i = 0; i < 12; i++) {
        rtn.push({ ym: tmpToday.subtract(1, "M").format("YYYY-MM") });
      }
      return rtn;
    },
    getUserList(comcode, targetYM) {
      api
        .get(this.host + "/api/userjob/" + comcode + "/" + targetYM)
        .then(response => {
          this.userList = response.data;
        });
    },
    cardColor: function(disp) {
      switch (disp) {
        case "起票":
          return "grey darken-3";
        case "1次否決":
        case "最終否決":
          return "red darken-3";
        case "1次承認":
          return "green darken-3";
        case "最終承認":
          return "blue darken-3";
        default:
          return "white";
      }
    },
    punchIn: function() {
      if (this.$refs.inputform.validate()) {
        let formData = new FormData();
        const token = "Token " + sessionStorage.getItem("token");
        let config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token
          }
        };
        formData.append("user", this.userinfo["username"]);
        formData.append("jobid", this.joined_job.job_id);
        formData.append("date", this.toDay);
        formData.append("in_time", moment().format());
        formData.append("approver1", this.joined_job.first_approver.username);
        formData.append("approver2", this.joined_job.second_approver.username);
        api({
          method: "post",
          url: this.host + "/api/attendance/in/",
          header: config.headers,
          data: formData
        }).then(() => {
          const message = "出勤しました。";
          this.$store.dispatch("message/setInfoMessage", { message: message });
        });
      }
    },
    punchOut: function() {
      if (this.$refs.inputform.validate() && this.$refs.breakform.validate()) {
        let formData = new FormData();
        const token = "Token " + sessionStorage.getItem("token");
        let config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token
          }
        };
        formData.append("out_time", moment().format());
        formData.append("break_time", this.breaktime);
        api({
          method: "patch",
          url:
            this.host +
            "/api/attendance/out/" +
            this.userinfo["username"] +
            "/" +
            this.toDay +
            "/" +
            this.joined_job.job_id +
            "/",
          header: config.headers,
          data: formData
        }).then(() => {
          const message = "退勤しました。";
          this.$store.dispatch("message/setInfoMessage", { message: message });
        });
      } else {
        alert("入力内容を確認してください。");
      }
    }
  }
};
</script>
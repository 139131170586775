<template>
  <v-app>
    <GlobalHeader />
    <GlobalMessage />
    <v-card flat>
      <template v-if="this.isApprove1">
        <v-form ref="approveForm" @submit.prevent="approveItem">
          <v-card-actions>
            <v-btn @click="setItem(1,true)" type="submit" dark class="mx-auto">1次承認</v-btn>
            <v-btn @click="setItem(1,false)" type="submit" dark class="mx-auto">1次否決</v-btn>
          </v-card-actions>
        </v-form>
      </template>
      <template v-if="this.isApprove2">
        <v-form ref="approveForm" @submit.prevent="approveItem">
          <v-card-actions>
            <v-btn @click="setItem(2,true)" type="submit" dark class="mx-auto">2次承認</v-btn>
            <v-btn @click="setItem(2,false)" type="submit" dark class="mx-auto">2次否決</v-btn>
          </v-card-actions>
        </v-form>
      </template>
      <v-dialog v-model="dialog" max-width="60%">
        <v-layout justify-center>
          <v-img
            contain
            v-if="(this.receipt != null)"
            :src="this.items.receipt"
            @click.stop="dialog = false"
          />
        </v-layout>
      </v-dialog>
      <v-card-text>
        <v-layout justify-center>
          <v-btn @click.stop="dialog = true" v-if="(this.receipt != null && (this.extention ==='jpg' || this.extention ==='jpeg' || this.extention ==='png'))">領収書表示</v-btn>
          <v-btn v-if="(this.receipt != null && (this.extention ==='pdf'))" target="_blank" :href="this.receipt" >領収書表示(別窓)</v-btn>
          <v-btn v-if="(this.receipt != null && this.extention !='pdf' && this.extention !='jpg' && this.extention !='png' && this.extention !='jpeg' )" disabled >領収書が対応外のファイル形式です</v-btn>
        </v-layout>
        <v-text-field v-model="by_create" label="起票者" prepend-icon="mdi-account-box" disabled />
        <v-text-field
          v-model="applicant"
          label="申請者"
          prepend-icon="mdi-account-arrow-right"
          disabled
        />
        <v-text-field v-model="job" label="ジョブ" prepend-icon="mdi-select-group" disabled />
        <v-text-field v-model="title" label="件名" prepend-icon="mdi-file-document" disabled />
        <v-text-field v-model="voucher" label="証憑番号" prepend-icon="mdi-numeric" disabled />
        <v-text-field v-model="status_disp" label="ステータス" prepend-icon="mdi-stairs" disabled />
        <v-text-field
          v-model="type_disp"
          label="費目"
          prepend-icon="mdi-format-list-bulleted"
          disabled
        />
        <v-text-field
          v-model="at_expense"
          label="支払日"
          prepend-icon="mdi-calendar-question"
          disabled
        />
        <v-text-field v-model="amount" label="支払額" prepend-icon="mdi-coin" disabled />
        <v-text-field
          v-model="destination"
          label="支払先"
          prepend-icon="mdi-office-building"
          disabled
          v-if="(this.type_disp != '交通費')"
        />
        <template v-if="(this.type_disp == '交通費')">
          <v-card-text class="px-0 pt-3 pb-0 grey--text">
            <v-icon class="mr-2" color="grey">mdi-train</v-icon>乗車区間
          </v-card-text>
          <v-container fluid class="py-0">
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="ride_from" label="From" disabled />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="ride_to" label="To" disabled />
              </v-col>
              <v-col cols="2">
                <v-switch v-model="ride_both" label="往復" disabled></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <v-textarea
          label="会議参加者"
          v-model="attendees"
          prepend-icon="mdi-account-multiple-check"
          auto-grow
          disabled
          v-if="(this.type_disp=='会議費')"
        />
        <v-textarea v-model="purpose" label="支払目的" prepend-icon="mdi-target" auto-grow disabled />
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import api from "@/services/api";
import moment from "moment";

export default {
  components: {
    GlobalHeader,
    GlobalMessage
  },
  data: () => ({
    items: null,
    by_create: null,
    joind_job: null,
    userinfo: null,
    receipt: null,
    isApprove1: null,
    isApprove2: null,
    i: null,
    b: null,
    isUpdated: false,
    dialog: false,
    applicant: null,
    job: null,
    title: null,
    voucher: null,
    status_disp: null,
    type_disp: null,
    at_expense: null,
    amount: null,
    destination: null,
    purpose: null,
    extention: null,
  }),
  created() {
    this.host = process.env.VUE_APP_API_BASE_URL;
    this.loadItem();
  },
  methods: {
    setItem: function(i, b) {
      this.i = i;
      this.b = b;
    },
    loadItem: function() {
      this.userinfo = JSON.parse(sessionStorage.getItem("user"));
      api
        .get(this.host + "/api/expenses/" + this.$route.params.uuid)
        .then(response => {
          if (response.data.length < 1) {
            this.$store.dispatch("message/setInfoMessage", {
              message: "該当するデータがありません"
            });
          } else {
            this.items = response.data;
            if (
              this.userinfo.username == this.items.by_create.username ||
              this.userinfo.username == this.items.approve1.username ||
              this.userinfo.username == this.items.approve2.username
            ) {
              this.by_create = this.items.by_create.full_name;
              this.applicant = this.items.applicant.full_name;
              this.job = this.items.jobname;
              this.title = this.items.title;
              this.type_disp = this.items.type_disp;
              this.at_expense = this.items.at_expense;
              this.amount = this.items.amount;
              this.destination = this.items.destination;
              this.receipt = this.items.receipt;
              if(this.receipt!=null){
                this.extention = this.receipt.split('?')[0].substr(this.receipt.split('?')[0].lastIndexOf('.')+1).toLowerCase();
              }
              this.ride_from = this.items.ride_from;
              this.ride_to = this.items.ride_to;
              this.ride_both = this.items.ride_both;
              this.purpose = this.items.purpose;
              this.attendees = this.items.attendees;
              this.voucher = this.items.voucher;
              this.status_disp = this.items.status_disp;
              if (
                this.userinfo.username == this.items.approve1.username &&
                this.items.status_disp == "起票"
              ) {
                this.isApprove1 = true;
              } else {
                this.isApprove1 = false;
              }
              if (
                this.userinfo.username == this.items.approve2.username &&
                this.items.status_disp == "1次承認"
              ) {
                this.isApprove2 = true;
              } else {
                this.isApprove2 = false;
              }
              if (!this.isUpdated) {
                this.$store.dispatch("message/setInfoMessage", {
                  message: "申請内容は以下の通りです"
                });
              } else {
                this.$store.dispatch("message/setInfoMessage", {
                  message: "申請状況を更新しました"
                });
              }
            } else {
              this.$store.dispatch("message/setInfoMessage", {
                message: "この申請を参照することはできません"
              });
            }
          }
        });
    },
    approveItem: function() {
      let formData = new FormData();
      const token = "Token " + sessionStorage.getItem("token");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token
        }
      };
      if (this.b) {
        formData.append("status", "ok" + this.i);
        formData.append("at_approve" + this.i, moment().format("YYYY-MM-DD"));
      } else {
        formData.append("status", "ng" + this.i);
      }

      api({
        method: "patch",
        url: this.host + "/api/okng/" + this.$route.params.uuid,
        header: config.headers,
        data: formData
      })
        .then(() => {
          this.isUpdated = true;
          this.loadItem();
        })
        .catch(err => {
          sessionStorage.setItem("ccc", err);
        })
        .finally(function() {
          sessionStorage.setItem("fin", "fine");
        });
    }
  }
};
</script>
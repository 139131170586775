<template>
  <v-app>
    <GlobalHeader />
    <GlobalMessage />
    <v-card flat tile class="px-2">
      <v-card-title>パスワード変更</v-card-title>
      <v-card-text>
        <v-form ref="inputform">
          <v-text-field
            v-model="login_user"
            label="ログインユーザ"
            prepend-icon="mdi-account-box"
            disabled
          />
          <v-text-field
            v-model="password"
            label="変更前のパスワード"
            prepend-icon="mdi-key"
            :rules="[rules.required,rules.min]"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            counter
          />
          <v-text-field
            v-model="cpassword"
            label="変更後のパスワード"
            prepend-icon="mdi-key-variant"
            :rules="[rules.required,rules.min]"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            hint="パスワードは最低 5 文字以上・よくあるフレーズはNG・数字のみNG"
            counter
          />
          <div id="submit">
            <v-card-actions>
              <v-btn v-on:click="change" dark class="mx-auto">変更</v-btn>
            </v-card-actions>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import api from "@/services/api";
// import moment from "moment";
export default {
  components: {
    GlobalHeader,
    GlobalMessage
  },
  data: () => ({
    show1: false,
    userinfo: null,
    login_user: null,
    password: null,
    cpassword: null,
    rules: {
      required: value => !!value || "必ず入力してください",
      min: v => v.length >= 5 || "パスワードは最低 5 文字以上・数字のみNG"
    }
  }),
  created() {
    // var moment = require("moment-timezone");
    this.host = process.env.VUE_APP_API_BASE_URL;
    this.userinfo = JSON.parse(sessionStorage.getItem("user"));
    this.login_user = this.userinfo["full_name"];
  },
  methods: {
    change: function() {
      if (this.$refs.inputform.validate()) {
        let formData = new FormData();
        const token = "Token " + sessionStorage.getItem("token");
        let config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token
          }
        };
        formData.append("current_password", this.password);
        formData.append("new_password", this.cpassword);
        api({
          method: "post",
          url: this.host + "/auth/users/set_password/",
          header: config.headers,
          data: formData
        }).then(() => {
          const message = "パスワードを変更しました";
          this.$store.dispatch("message/setInfoMessage", { message: message });
          //   this.$router.push("/");
        });
      } else {
        alert("入力内容を確認してください");
      }
    }
  }
};
</script>
<template>
  <v-app>
    <GlobalHeader />
    <GlobalMessage />
    <v-card>
      <v-card-title>承認一覧</v-card-title>
      <v-select
        v-model="targetYM"
        :items="ymList"
        item-text="ym"
        item-value="ym"
        label="対象年月"
        prepend-icon="mdi-calendar-question"
        class="mx-5 my-5"
        @change="
          getAttendanseItems();
          getExpenseItems();
        "
      />
    </v-card>
    <v-card>
      <v-card-title>
        勤怠承認
        <v-btn icon @click="getAttendanseItems()">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="検索"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="attendanseheaders"
        :items="attendanseItems"
        class="elevation-1 mx-5 my-5"
        :items-per-page="10"
        locale="ja-JP"
        :search="search"
      >
        <template v-slot:[`item.actions1`]="{ item }">
          <v-icon
            v-if="item.approver1.username === username && item.approve1 != true"
            @click="approveAttendItem(item.id, 1, 'true')"
            class="ma-auto"
            >mdi-checkbox-marked</v-icon
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <v-icon
            v-if="item.approver1.username === username && item.approve1 != true"
            @click="approveAttendItem(item.id, 1, 'false')"
            class="ml-6"
            >mdi-close-box</v-icon
          >
        </template>
        <template v-slot:[`item.actions2`]="{ item }">
          <v-icon
            v-if="
              item.approver2.username === username &&
                item.approve1 == true &&
                item.approve2 != true
            "
            @click="approveAttendItem(item.id, 2, 'true')"
            class="ma-auto"
            >mdi-checkbox-multiple-marked</v-icon
          >
          <v-icon
            v-if="
              item.approver2.username === username &&
                item.approve1 == true &&
                item.approve2 != true
            "
            @click="approveAttendItem(item.id, 2, 'false')"
            class="ml-6"
            >mdi-close-circle</v-icon
          >
        </template>
        <template v-slot:[`item.status`]="{ item }">{{
          getStatus(item.approve1, item.approve2)
        }}</template>
        <template v-slot:[`item.wday`]="{ item }">
          <v-chip
            v-if="item.hday"
            color="red"
            text-color="white"
            class="pa-2 ma-auto"
            >{{ getWeekday(item.date) }}</v-chip
          >
          <v-chip v-else color="grey" text-color="white" class="pa-2 ma-auto">{{
            getWeekday(item.date)
          }}</v-chip>
        </template>
        <template v-slot:[`item.in_time`]="{ item }">{{
          dateFormat(item.in_time)
        }}</template>
        <template v-slot:[`item.out_time`]="{ item }">{{
          dateFormat(item.out_time)
        }}</template>
        <template v-slot:[`item.work_time`]="{ item }">{{
          calWorkTime(item.in_time, item.out_time, item.break_time)
        }}</template>
        <template v-slot:[`item.jobid`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="black" dark v-on="on">{{ item.jobid }}</v-btn>
            </template>
            <span>{{ item.jobname }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-card>
      <v-card-title>
        経費承認
        <v-btn icon @click="getExpenseItems()">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search2"
          append-icon="mdi-magnify"
          label="検索"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="expenseheaders"
        :items="expenseItems"
        class="elevation-1 mx-5 my-5"
        :items-per-page="10"
        locale="ja-JP"
        :search="search2"
      >
        <template v-slot:[`item.title`]="{ item }">
          <a :href="'/item/' + item.id" target="_blank" rel="opener">{{ item.title }}</a>
        </template>
        <template v-slot:[`item.jobid`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="black" dark v-on="on">{{ item.jobid }}</v-btn>
            </template>
            <span>{{ item.jobname }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import api from "@/services/api";
import moment from "moment";
const JapaneseHolidays = require("japanese-holidays");
export default {
  components: {
    GlobalHeader,
    GlobalMessage,
  },
  data: () => ({
    search: "",
    search2: "",
    host: null,
    userinfo: null,
    username: null,
    attendanseheaders: [
      { text: "承認状態", value: "status" },
      { text: "1次", value: "actions1", sortable: false, align: "center" },
      { text: "2次", value: "actions2", sortable: false, align: "center" },
      { text: "年月", value: "date" },
      { text: "勤務者", value: "username" },
      { text: "曜日", value: "wday", sortable: false, align: "center" },
      { text: "ジョブ", value: "jobid" },
      { text: "出勤時刻", value: "in_time" },
      { text: "退勤時刻", value: "out_time" },
      { text: "休憩時間", value: "break_time" },
      { text: "勤務時間", value: "work_time" },
    ],
    expenseheaders: [
      { text: "承認状態", value: "status_disp" },
      { text: "支払日", value: "at_expense" },
      { text: "ジョブID", value: "jobid" },
      { text: "起票者", value: "creatorname" },
      { text: "申請者", value: "applicantname" },
      { text: "件名", value: "title" },
      { text: "費目", value: "type_disp" },
      { text: "金額", value: "amount" },
    ],
    expenseItems: [],
    attendanseItems: [],
    targetYM: null,
    ymList: null,
  }),
  created() {
    // var moment = require("moment-timezone");
    this.host = process.env.VUE_APP_API_BASE_URL;
    this.userinfo = JSON.parse(sessionStorage.getItem("user"));
    this.username = this.userinfo["username"];
    if (this.targetYM == null) {
      this.targetYM = moment().format("YYYY-MM");
    }
    this.targetMonth = this.targetYM;
    this.targetYYYY = this.targetMonth.substr(0, 4);
    this.targetMM = this.targetMonth.substr(-2);
    this.ymList = this.getYmList();

    this.getAttendanseItems();
    this.getExpenseItems();
  },
  methods: {
    approveAttendItem(pk, phase, val) {
      let formData = new FormData();
      const token = "Token " + sessionStorage.getItem("token");
      let config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: token,
        },
      };
      formData.append("approve" + phase, val);
      api({
        method: "patch",
        url: this.host + "/api/attendapprove/" + pk + "/",
        header: config.headers,
        data: formData,
      }).then(() => {
        this.getAttendanseItems();
      });
    },
    getHHMM(minutes) {
      if (minutes != "") {
        let h = this.omission(minutes / 60);
        let m = minutes % 60;
        if (String(h).length == 1) {
          if (String(h) == "0" && minutes < 0) {
            h = "-00";
          } else {
            h = String(h).padStart(2, "0");
          }
        }
        m = String(m).padStart(2, "0");
        if (String(m).charAt(0) === "-") {
          m = m.substr(1, 2).padStart(2, "0");
        }
        return h + ":" + m;
      } else {
        return minutes;
      }
    },
    omission(Num) {
      if (Num >= 0) {
        return Math.floor(Num);
      } else {
        return Math.ceil(Num);
      }
    },
    getYmList() {
      const moment = require("moment");
      const toDay = moment().format("YYYY-MM-DD");
      let tmpToday = moment(toDay).add(1, "M");
      let rtn = [];
      for (let i = 0; i < 12; i++) {
        rtn.push({ ym: tmpToday.subtract(1, "M").format("YYYY-MM") });
      }
      return rtn;
    },
    getWeekday(strdate) {
      const weekday = ["日", "月", "火", "水", "木", "金", "土"];
      return weekday[moment(strdate).day()];
    },
    dateFormat(datestr) {
      return moment(datestr).format("YYYY-MM-DD HH:mm");
    },
    getStatus(ap1, ap2) {
      if (!ap1 && !ap2) {
        if ((ap1 == false) | (ap2 == false)) {
          return "×";
        } else {
          return "ー";
        }
      } else if (ap1 && !ap2) {
        return "△";
      } else if (ap1 && ap2) {
        return "◎";
      } else {
        return "×";
      }
    },
    calWorkTime(in_time, out_time, break_time) {
      let diff = moment(out_time)
        .startOf("minute")
        .diff(moment(in_time).startOf("minute"), "minutes");
      diff = diff - break_time;
      return this.getHHMM(diff);
    },
    getAttendanseItems() {
      api
        .get(
          this.host + "/api/attendapp/" + this.username + "/" + this.targetYM
        )
        .then((response) => {
          let output = [];
          this.monthData = this.createMonthData(this.targetYM);
          for (let loopobj of this.monthData) {
            let target = response.data.filter((obj) => {
              return obj.date === loopobj.date;
            });
            if (target.length > 0) {
              for (let i = 0; i < target.length; i++) {
                let rtnobj = {};
                rtnobj["date"] = loopobj.date;
                rtnobj["wday"] = loopobj.wday;
                rtnobj["hday"] = loopobj.hday;
                rtnobj["in_time"] = target[i].in_time;
                rtnobj["out_time"] = target[i].out_time;
                rtnobj["break_time"] = target[i].break_time;
                rtnobj["jobid"] = target[i].jobid;
                rtnobj["jobname"] = target[i].jobname;
                rtnobj["user"] = target[i].user;
                rtnobj["username"] = target[i].username;
                rtnobj["approve1"] = target[i].approve1;
                rtnobj["approve2"] = target[i].approve2;
                rtnobj["approver1"] = target[i].approver1;
                rtnobj["approver2"] = target[i].approver2;
                rtnobj["id"] = target[i].id;
                output.push(rtnobj);
              }
            }
          }
          this.attendanseItems = output;
        });
    },
    getExpenseItems() {
      api
        .get(this.host + "/api/exapp/" + this.username + "/" + this.targetYM)
        .then((response) => {
          this.expenseItems = response.data;
        });
    },

    createMonthData: function(target) {
      const weekday = ["日", "月", "火", "水", "木", "金", "土"];
      let tagetDate = moment(target);
      const daysInMonth = tagetDate.daysInMonth();
      //返却配列
      let dateArray = [];
      //ループ回す前に1日減算
      tagetDate.subtract(1, "d");
      for (let i = 0; i < daysInMonth; i++) {
        let dateObj = {};
        tagetDate.add(1, "d");
        dateObj["date"] = tagetDate.format("YYYY-MM-DD");
        let wdayIndex = tagetDate.day();
        dateObj["wday"] = weekday[wdayIndex];
        if (
          wdayIndex == 0 ||
          wdayIndex == 6 ||
          JapaneseHolidays.isHoliday(tagetDate.toDate())
        ) {
          dateObj["hday"] = "休日";
        } else {
          dateObj["hday"] = "";
        }
        dateArray.push(dateObj);
      }
      return dateArray;
    },
  },
};
</script>

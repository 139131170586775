<template>
  <!-- メッセージエリア -->

  <v-card v-if="(message.error.length>0)">
    <v-alert type="danger" flat text v-show="message.error" class="mb-0">{{ message.error }}</v-alert>
  </v-card>
  <v-card v-else-if="(message.warnings.length>0)">
    <v-alert type="warning" flat text v-show="message.warnings.length > 0" class="mb-0">
      <p v-for="warning in message.warnings" :key="warning.id" class="mb-0">{{ warning }}</p>
    </v-alert>
  </v-card>
  <v-card v-else-if="(message.info.length>0)">
    <v-alert type="info" flat text v-show="message.info" class="mb-0">{{ message.info }}</v-alert>
  </v-card>
  <v-card v-else></v-card>
</template>

<script>
export default {
  computed: {
    message: function() {
      return this.$store.state.message;
    }
  }
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('GlobalHeader'),_c('GlobalMessage'),_c('v-card',[_c('v-card-title',[_vm._v("承認一覧")]),_c('v-select',{staticClass:"mx-5 my-5",attrs:{"items":_vm.ymList,"item-text":"ym","item-value":"ym","label":"対象年月","prepend-icon":"mdi-calendar-question"},on:{"change":function($event){_vm.getAttendanseItems();
        _vm.getExpenseItems();}},model:{value:(_vm.targetYM),callback:function ($$v) {_vm.targetYM=$$v},expression:"targetYM"}})],1),_c('v-card',[_c('v-card-title',[_vm._v(" 勤怠承認 "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getAttendanseItems()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 mx-5 my-5",attrs:{"headers":_vm.attendanseheaders,"items":_vm.attendanseItems,"items-per-page":10,"locale":"ja-JP","search":_vm.search},scopedSlots:_vm._u([{key:"item.actions1",fn:function(ref){
        var item = ref.item;
return [(item.approver1.username === _vm.username && item.approve1 != true)?_c('v-icon',{staticClass:"ma-auto",on:{"click":function($event){return _vm.approveAttendItem(item.id, 1, 'true')}}},[_vm._v("mdi-checkbox-marked")]):_vm._e(),_vm._v(" "),(item.approver1.username === _vm.username && item.approve1 != true)?_c('v-icon',{staticClass:"ml-6",on:{"click":function($event){return _vm.approveAttendItem(item.id, 1, 'false')}}},[_vm._v("mdi-close-box")]):_vm._e()]}},{key:"item.actions2",fn:function(ref){
        var item = ref.item;
return [(
            item.approver2.username === _vm.username &&
              item.approve1 == true &&
              item.approve2 != true
          )?_c('v-icon',{staticClass:"ma-auto",on:{"click":function($event){return _vm.approveAttendItem(item.id, 2, 'true')}}},[_vm._v("mdi-checkbox-multiple-marked")]):_vm._e(),(
            item.approver2.username === _vm.username &&
              item.approve1 == true &&
              item.approve2 != true
          )?_c('v-icon',{staticClass:"ml-6",on:{"click":function($event){return _vm.approveAttendItem(item.id, 2, 'false')}}},[_vm._v("mdi-close-circle")]):_vm._e()]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm.getStatus(item.approve1, item.approve2)))]}},{key:"item.wday",fn:function(ref){
          var item = ref.item;
return [(item.hday)?_c('v-chip',{staticClass:"pa-2 ma-auto",attrs:{"color":"red","text-color":"white"}},[_vm._v(_vm._s(_vm.getWeekday(item.date)))]):_c('v-chip',{staticClass:"pa-2 ma-auto",attrs:{"color":"grey","text-color":"white"}},[_vm._v(_vm._s(_vm.getWeekday(item.date)))])]}},{key:"item.in_time",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm.dateFormat(item.in_time)))]}},{key:"item.out_time",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm.dateFormat(item.out_time)))]}},{key:"item.work_time",fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm.calWorkTime(item.in_time, item.out_time, item.break_time)))]}},{key:"item.jobid",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","dark":""}},on),[_vm._v(_vm._s(item.jobid))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.jobname))])])]}}],null,true)})],1),_c('v-card',[_c('v-card-title',[_vm._v(" 経費承認 "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getExpenseItems()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":""},model:{value:(_vm.search2),callback:function ($$v) {_vm.search2=$$v},expression:"search2"}})],1),_c('v-data-table',{staticClass:"elevation-1 mx-5 my-5",attrs:{"headers":_vm.expenseheaders,"items":_vm.expenseItems,"items-per-page":10,"locale":"ja-JP","search":_vm.search2},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":'/item/' + item.id,"target":"_blank","rel":"opener"}},[_vm._v(_vm._s(item.title))])]}},{key:"item.jobid",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","dark":""}},on),[_vm._v(_vm._s(item.jobid))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.jobname))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app>
    <GlobalHeader />
    <GlobalMessage />
    <v-card flat>
      <v-card-text>
        <v-form ref="inputform" @submit.prevent="createItem">
          <v-select
            v-model="joined_job"
            :items="job_data"
            item-text="job_name"
            item-value="job_id"
            label="ジョブ"
            prepend-icon="mdi-select-group"
            @change="getApprover"
            :rules="[required]"
            return-object
          />
          <v-text-field v-model="by_create" label="起票者" prepend-icon="mdi-account-box" disabled />
          <v-select
            v-model="applicant"
            :items="applicants"
            item-text="full_name"
            item-value="username"
            label="申請者"
            prepend-icon="mdi-account-arrow-right"
            :rules="[required]"
          />
          <v-text-field
            v-model="title"
            label="件名"
            prepend-icon="mdi-file-document"
            :rules="[required]"
          />
          <v-select
            v-model="type_expense"
            :items="type_items"
            item-text="val"
            item-value="key"
            label="費目"
            prepend-icon="mdi-format-list-bulleted"
            @change="inputItems"
            :rules="[required]"
          />
          <v-menu
            :close-on-content-click="false"
            max-width="290"
            ref="is_at_expense"
            v-model="is_at_expense"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="computedDateFormattedMomentjs"
                clearable
                label="支払日"
                readonly
                v-on="on"
                prepend-icon="mdi-calendar-question"
                :rules="[required]"
              />
            </template>
            <v-date-picker
              v-model="at_expense"
              no-title
              @input="is_at_expense = false"
              locale="ja"
              :max="this.maxdate()"
              :min="this.mindate()"
            ></v-date-picker>
          </v-menu>
          <v-text-field
            v-model="amount"
            label="支払額"
            prepend-icon="mdi-coin"
            :rules="[required,isNumberField]"
          />
          <v-text-field
            v-model="destination"
            label="支払先"
            prepend-icon="mdi-office-building"
            v-if="display.is_destination"
            :rules="[required]"
          />
          <template v-if="display.is_ride">
            <v-card-text class="px-0 pt-3 pb-0 subtitle-1">
              <v-icon class="mr-2">mdi-train</v-icon>乗車区間
            </v-card-text>
            <v-container fluid class="py-0">
              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="ride_from" label="From" :rules="[required]" />
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="ride_to" label="To" :rules="[required]" />
                </v-col>
                <v-col cols="2">
                  <v-switch v-model="ride_both" label="往復"></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-textarea
            label="会議参加者"
            v-model="attendees"
            v-if="display.is_meeting"
            prepend-icon="mdi-account-multiple-check"
            :rules="[required]"
          />
          <v-textarea v-model="purpose" label="支払目的" prepend-icon="mdi-target" :rules="[required]"></v-textarea>
          <v-file-input
            v-model="receipt"
            label="領収書"
            v-if="display.is_receipt"
            ref="file"
            :rules="[required]"
          />
          <v-card-actions>
            <v-btn type="submit" dark class="mx-auto">送信</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import moment from "moment";
import api from "@/services/api";

export default {
  components: {
    GlobalHeader,
    GlobalMessage
  },
  data: () => ({
    job_data: [],
    is_at_expense: false,
    display: {
      is_destination: false,
      is_ride: false,
      is_receipt: false,
      is_meeting: false
    },
    by_create: null,
    joined_job: null,
    title: null,
    type_expense: null,
    at_expense: null,
    amount: null,
    destination: null,
    ride_from: null,
    ride_to: null,
    ride_both: false,
    attendees: null,
    purpose: null,
    receipt: null,
    required: value => !!value || "必ず入力してください",
    isNumberField: value => isFinite(value) || "数値で入力してください",
    maxdate: () => {
      return moment()
        .endOf("month")
        .format("YYYY-MM-DD");
    },
    mindate: () => {
      return moment()
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    userinfo: null,
    approver1: null,
    approver2: null,
    applicants: [],
    applicant: null
  }),
  computed: {
    type_items: () => [
      { key: "6112", val: "交通費旅費" },
      { key: "5463", val: "交通費" },
      { key: "6217", val: "事務消耗品" },
      { key: "5435", val: "厚生費" },
      { key: "6222", val: "会議費" },
      { key: "9999", val: "その他" }
    ],
    computedDateFormattedMomentjs() {
      return this.at_expense
        ? moment(this.at_expense).format("YYYY-MM-DD")
        : "";
    }
  },
  created() {
    this.host = process.env.VUE_APP_API_BASE_URL;
    this.userinfo = JSON.parse(sessionStorage.getItem("user"));
    this.default_disp();
    this.getJobList(this.userinfo.username, moment().format("YYYY-MM"));
    this.by_create = this.userinfo["full_name"];
    this.$store.dispatch("message/setInfoMessage", {
      message: "以下の情報を入力してください"
    });
  },
  methods: {
    getJobList(user, targetYM) {
      api
        .get(this.host + "/api/joinedjob/" + user + "/" + targetYM)
        .then(response => {
          this.job_data = response.data;
        });
    },
    default_disp: function() {
      this.display.is_destination = true;
      this.display.is_ride = false;
      this.display.is_receipt = true;
      this.display.is_meeting = false;
    },
    inputItems: function() {
      this.default_disp();
      if (this.type_expense == "5463") {
        this.display.is_destination = false;
        this.display.is_ride = true;
        this.display.is_receipt = false;
      } else if (this.type_expense == "6222") {
        this.display.is_meeting = true;
      }
    },
    getApprover: function() {
      this.applicants = [];
      this.approver1 = this.joined_job.first_approver.username;
      this.approver2 = this.joined_job.second_approver.username;
      this.applicants.push({
        username: this.userinfo["username"],
        full_name: this.userinfo["full_name"]
      });
      if (this.approver1 != this.userinfo["username"]) {
        this.applicants.push({
          username: this.joined_job.first_approver.username,
          full_name: this.joined_job.first_approver.full_name
        });
      }
      if (this.approver2 != this.userinfo["username"]) {
        this.applicants.push({
          username: this.joined_job.second_approver.username,
          full_name: this.joined_job.second_approver.full_name
        });
      }
    },
    createItem: function() {
      //バリデーションが通過したらフォームに送信
      if (this.$refs.inputform.validate()) {
        //Formデータに格納
        let formData = new FormData();
        const token = "Token " + sessionStorage.getItem("token");
        let config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token
          }
        };
        formData.append("title", this.title);
        formData.append("amount", this.amount);
        formData.append("purpose", this.purpose);
        formData.append("at_expense", this.at_expense);
        formData.append("voucher", "dummy");
        formData.append("by_create", this.userinfo["username"]);
        formData.append("applicant", this.applicant);
        formData.append("depcode", this.userinfo["user_dep"]["dep_code"]);
        formData.append("depname", this.userinfo["user_dep"]["dep_name"]);
        formData.append("approve1", this.approver1);
        formData.append("approve2", this.approver2);
        formData.append("jobid", this.joined_job.job_id);
        formData.append("jobname", this.joined_job.job_name);
        formData.append("at_expense", this.at_expense);
        formData.append("type_expense", this.type_expense);
        //交通費は支払先と領収書不要・乗車区間と往復フラグ必要
        if (this.type_expense != "5463") {
          formData.append("destination", this.destination);
          formData.append("receipt", this.receipt);
        } else {
          formData.append("ride_from", this.ride_from);
          formData.append("ride_to", this.ride_to);
          formData.append("ride_both", this.ride_both);
        }
        //会議費は会議同席者が必要
        if (this.type_expense == "6222") {
          formData.append("attendees", this.attendees);
        }
        //APIを呼び出す
        api({
          method: "post",
          url: this.host + "/api/expenses/",
          header: config.headers,
          data: formData
        }).then(() => {
          const message = "登録しました。";
          this.$store.dispatch("message/setInfoMessage", { message: message });
          this.$router.push("/");
        });
      }
    }
  }
};
</script>
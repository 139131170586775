<template>
  <v-app>
    <GlobalHeader />
    <GlobalMessage />
    <template>
      <v-card>
        <v-card-title>
          <v-btn icon @click="init(prefYM)">
            <v-icon>mdi-arrow-left-bold-circle</v-icon>
          </v-btn>
          勤務実績 {{ targetYYYY }}年{{ targetMM }}月
          <v-btn icon @click="init(nextYM)">
            <v-icon>mdi-arrow-right-bold-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ul>
            <li>勤務者名：{{ username }}</li>
            <li v-if="targetUser.charAt(0) != 'P'">稼働時間：{{ total }}</li>
            <li>使用経費：{{ etotal }}円</li>
          </ul>
        </v-card-text>
      </v-card>
      <v-card v-if="targetUser.charAt(0) != 'P'">
        <v-card-title>勤務表</v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          :items-per-page="100"
          locale="ja-JP"
          hide-default-footer
          disable-sort
          disable-filtering
          disable-pagination
          dense
        >
          <template v-slot:[`item.wday`]="{ item }">
            <v-chip
              v-if="item.hday"
              color="red"
              text-color="white"
              class="pa-2 ma-0"
              >{{ getWeekday(item.date) }}</v-chip
            >
            <v-chip v-else color="grey" text-color="white" class="pa-2 ma-0">{{
              getWeekday(item.date)
            }}</v-chip>
          </template>
          <template v-slot:[`item.jobid`]="{ item }">
            <v-tooltip bottom v-if="item.jobid && item.jobid != '-------'">
              <template v-slot:activator="{ on }">
                <v-btn color="black" dark v-on="on">{{ item.jobid }}</v-btn>
              </template>
              <span>{{ item.jobname }}</span>
            </v-tooltip>
            <v-btn
              color="red"
              dark
              v-else-if="item.jobid && item.jobid == '-------'"
              >休暇</v-btn
            >
          </template>
          <template v-slot:[`item.status`]="{ item }">{{
            getStatus(item.approve1, item.approve2)
          }}</template>
          <template v-slot:[`item.in_time`]="{ item }">{{
            dateFormat(item.in_time)
          }}</template>
          <template v-slot:[`item.out_time`]="{ item }">{{
            dateFormat(item.out_time)
          }}</template>
          <template
            v-slot:[`item.actions`]="{ item }"
            v-if="userinfo.username == targetUser"
          >
            <v-icon @click="addAttendItem(item)">mdi-plus-circle</v-icon>
            <v-icon v-if="item.approve2 != true" @click="editAttendItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="item.approve2 != true" @click="delAttendItem(item.id)"
              >mdi-delete-forever</v-icon
            >
            <v-icon v-if="!item.jobid" @click="vacAttendItem(item.date)"
              >mdi-sleep</v-icon
            >
          </template>
        </v-data-table>
        <v-dialog v-model="attendDialog" max-width="500px">
          <v-card v-if="editedItem">
            <v-form ref="modform" @submit.prevent="doModAttend">
              <v-card-title class="pa-3"
                >{{ editedItem.date }}({{ editedItem.wday }})</v-card-title
              >
              <v-text-field
                v-model="mod_JobName"
                label="JOB名"
                prepend-icon="mdi-select-group"
                disabled
                class="ma-3"
              />
              <v-menu
                :close-on-content-click="false"
                max-width="290"
                v-model="is_mod_in_date"
                class="ma-3"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="
                      computedDateFormattedMomentjsFromStrDate(mod_in_date)
                    "
                    clearable
                    label="勤務開始日"
                    readonly
                    v-on="on"
                    prepend-icon="mdi-calendar-question"
                    class="ma-3"
                  />
                </template>
                <v-date-picker
                  v-model="mod_in_date"
                  no-title
                  @input="is_mod_in_date = false"
                  locale="ja"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu_mod_in_time"
                v-model="is_mod_in_time"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="mod_in_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="mod_in_time"
                    label="勤務開始時間"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    v-on="on"
                    class="ma-3"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="is_mod_in_time"
                  v-model="mod_in_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu_mod_in_time.save(mod_in_time)"
                ></v-time-picker>
              </v-menu>
              <v-menu
                :close-on-content-click="false"
                max-width="290"
                v-model="is_mod_out_date"
                class="ma-3"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="
                      computedDateFormattedMomentjsFromStrDate(mod_out_date)
                    "
                    clearable
                    label="勤務終了日"
                    readonly
                    v-on="on"
                    prepend-icon="mdi-calendar-question"
                    class="ma-3"
                  />
                </template>
                <v-date-picker
                  v-model="mod_out_date"
                  no-title
                  @input="is_mod_out_date = false"
                  locale="ja"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu_mod_out_time"
                v-model="is_mod_out_time"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="mod_out_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="mod_out_time"
                    label="勤務終了時間"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    v-on="on"
                    class="ma-3"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="is_mod_out_time"
                  v-model="mod_out_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu_mod_out_time.save(mod_out_time)"
                ></v-time-picker>
              </v-menu>
              <v-text-field
                v-model="mod_BreakTime"
                label="休憩時間"
                prepend-icon="mdi-timer-off"
                class="ma-3"
              />
              <v-card-actions>
                <v-btn @click="attendDialog = false" dark class="mx-auto"
                  >キャンセル</v-btn
                >
                <v-btn type="submit" dark class="mx-auto">打刻修正</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="attendAddDialog" max-width="500px">
          <v-card v-if="editedItem">
            <v-form ref="addform" @submit.prevent="doAddAttend">
              <v-card-title class="pa-3"
                >{{ editedItem.date }}({{ editedItem.wday }})</v-card-title
              >
              <v-select
                v-model="joined_job"
                :items="job_data"
                item-text="job_name"
                item-value="job_id"
                label="ジョブ"
                prepend-icon="mdi-select-group"
                :rules="[rules.required]"
                return-object
                class="ma-3"
              />
              <v-menu
                :close-on-content-click="false"
                max-width="290"
                v-model="is_add_in_date"
                class="ma-3"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="
                      computedDateFormattedMomentjsFromStrDate(add_in_date)
                    "
                    clearable
                    label="勤務開始日"
                    readonly
                    v-on="on"
                    prepend-icon="mdi-calendar-question"
                    class="ma-3"
                    :rules="[rules.required]"
                  />
                </template>
                <v-date-picker
                  v-model="add_in_date"
                  no-title
                  @input="is_add_in_date = false"
                  locale="ja"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu_add_in_time"
                v-model="is_add_in_time"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="add_in_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="add_in_time"
                    label="勤務開始時間"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    v-on="on"
                    class="ma-3"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="is_add_in_time"
                  v-model="add_in_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu_add_in_time.save(add_in_time)"
                ></v-time-picker>
              </v-menu>
              <v-menu
                :close-on-content-click="false"
                max-width="290"
                v-model="is_add_out_date"
                class="ma-3"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="
                      computedDateFormattedMomentjsFromStrDate(add_out_date)
                    "
                    clearable
                    label="勤務終了日"
                    readonly
                    v-on="on"
                    prepend-icon="mdi-calendar-question"
                    class="ma-3"
                    :rules="[rules.required]"
                  />
                </template>
                <v-date-picker
                  v-model="add_out_date"
                  no-title
                  @input="is_add_out_date = false"
                  locale="ja"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu_add_out_time"
                v-model="is_add_out_time"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="add_out_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="add_out_time"
                    label="勤務終了時間"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    v-on="on"
                    class="ma-3"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="is_add_out_time"
                  v-model="add_out_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu_add_out_time.save(add_out_time)"
                ></v-time-picker>
              </v-menu>
              <v-text-field
                v-model="add_BreakTime"
                label="休憩時間"
                prepend-icon="mdi-timer-off"
                class="ma-3"
              />
              <v-card-actions>
                <v-btn @click="closeAddForm" dark class="mx-auto"
                  >キャンセル</v-btn
                >
                <v-btn type="submit" dark class="mx-auto">打刻追加</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card>
      <v-card>
        <v-card-title>経費</v-card-title>
        <v-data-table
          :headers="eheaders"
          :items="eitems"
          class="ma-3"
          :items-per-page="100"
          locale="ja-JP"
          hide-default-footer
          disable-sort
          disable-filtering
          disable-pagination
        >
          <template
            v-slot:[`item.actions`]="{ item }"
            v-if="userinfo.username == targetUser"
          >
            <v-icon
              v-if="item.status_disp != '最終承認'"
              @click="delExItem(item.id)"
              >mdi-delete-forever</v-icon
            >
          </template>
          <template v-slot:[`item.jobid`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="black" dark v-on="on">{{ item.jobid }}</v-btn>
              </template>
              <span>{{ item.jobname }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </v-app>
</template>
<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import api from "@/services/api";
import moment from "moment";
const JapaneseHolidays = require("japanese-holidays");
export default {
  components: {
    GlobalHeader,
    GlobalMessage,
  },
  data: () => ({
    attendDialog: false,
    attendAddDialog: false,
    targetUser: null,
    targetMonth: null,
    items: [],
    monthData: null,
    headers: [
      { text: "承認", value: "status", align: "center" },
      { text: "年月", value: "date" },
      { text: "曜日", value: "wday", align: "center" },
      { text: "ジョブ", value: "jobid" },
      { text: "出勤時刻", value: "in_time" },
      { text: "退勤時刻", value: "out_time" },
      { text: "休憩時間", value: "break_time" },
      { text: "勤務時間", value: "work_time" },
      { text: "操作", value: "actions", sortable: false, align: "left" },
    ],
    total: null,
    username: null,
    targetYYYY: null,
    targetMM: null,
    eheaders: [
      { text: "承認", value: "status_disp" },
      { text: "支払日", value: "at_expense" },
      { text: "ジョブ", value: "jobid" },
      { text: "件名", value: "title" },
      { text: "費目", value: "type_disp" },
      { text: "支払額", value: "amount" },
      { text: "操作", value: "actions", sortable: false, align: "left" },
    ],
    eitems: [],
    etotal: 0,
    mod_JobName: null,
    mod_JobId: null,
    mod_date: null,
    mod_in_date: null,
    mod_in_time: null,
    mod_out_date: null,
    mod_out_time: null,
    mod_BreakTime: 0,
    is_mod_in_date: false,
    is_mod_in_time: false,
    is_mod_out_date: false,
    is_mod_out_time: false,
    add_JobName: null,
    add_JobId: null,
    add_date: null,
    add_in_date: null,
    add_in_time: null,
    add_out_date: null,
    add_out_time: null,
    add_BreakTime: 0,
    is_add_in_date: false,
    is_add_in_time: false,
    is_add_out_date: false,
    is_add_out_time: false,
    editedItem: null,
    userinfo: null,
    joined_job: null,
    job_data: [],
    prefYM: null,
    nextYM: null,
    keyYM: null,
    rules: {
      required: (value) => !!value || "必ず入力してください",
    },
  }),
  created() {
    this.host = process.env.VUE_APP_API_BASE_URL;
    this.userinfo = JSON.parse(sessionStorage.getItem("user"));
    this.targetUser = this.$route.params.user;
    this.getUsername();

    this.targetMonth = this.$route.params.month;
    this.init(this.targetMonth);
  },
  methods: {
    isVacation(jobcd) {
      if (jobcd == "-------") {
        return "休暇";
      } else {
        return jobcd;
      }
    },
    init(tagetYM) {
      this.targetYYYY = tagetYM.substr(0, 4);
      this.targetMM = tagetYM.substr(-2);
      this.prefYM = moment(tagetYM).subtract(1, "M").format("YYYY-MM");
      this.nextYM = moment(tagetYM).add(1, "M").format("YYYY-MM");
      this.keyYM = tagetYM;
      this.loadItem();
      this.loadEItem();
    },
    closeAddForm() {
      this.attendAddDialog = false;
    },
    getStatus(ap1, ap2) {
      if (!ap1 && !ap2) {
        if ((ap1 == false) | (ap2 == false)) {
          return "×";
        } else {
          return "ー";
        }
      } else if (ap1 && !ap2) {
        if ((ap1 == false) | (ap2 == false)) {
          return "×";
        } else {
          return "△";
        }
      } else if (ap1 && ap2) {
        return "◎";
      } else {
        return "×";
      }
    },
    vacAttendItem(date) {
      let formData = new FormData();
      const token = "Token " + sessionStorage.getItem("token");
      let config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: token,
        },
      };
      formData.append("user", this.userinfo["username"]);
      formData.append("date", date);
      api({
        method: "post",
        url: this.host + "/api/vacation/",
        header: config.headers,
        data: formData,
      }).then(() => {
        alert("休暇登録しました");
        this.init(this.keyYM);
      });
    },
    doAddAttend: function () {
      if (this.$refs.addform.validate()) {
        let formData = new FormData();
        const token = "Token " + sessionStorage.getItem("token");
        let config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
          },
        };
        formData.append("user", this.userinfo["username"]);
        formData.append("jobid", this.joined_job.job_id);
        formData.append("date", this.add_date);
        formData.append(
          "in_time",
          this.add_in_date + " " + this.add_in_time + ":00"
        );
        formData.append(
          "out_time",
          this.add_out_date + " " + this.add_out_time + ":00"
        );
        formData.append("break_time", this.add_BreakTime);
        formData.append("approver1", this.joined_job.first_approver.username);
        formData.append("approver2", this.joined_job.second_approver.username);
        api({
          method: "post",
          url: this.host + "/api/attendadd/",
          header: config.headers,
          data: formData,
        }).then(() => {
          this.attendAddDialog = false;
          alert("勤務時間を追加しました");
          this.init(this.keyYM);
        });
      } else {
        alert("入力内容を確認してください");
      }
    },
    doModAttend: function () {
      let formData = new FormData();
      const token = "Token " + sessionStorage.getItem("token");
      let config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: token,
        },
      };
      formData.append(
        "in_time",
        this.mod_in_date + " " + this.mod_in_time + ":00"
      );
      formData.append(
        "out_time",
        this.mod_out_date + " " + this.mod_out_time + ":00"
      );
      formData.append("break_time", this.mod_BreakTime);
      formData.append("approve1", null);
      formData.append("approve2", null);
      api({
        method: "patch",
        url:
          this.host +
          "/api/attendance/" +
          this.userinfo.username +
          "/" +
          this.mod_date +
          "/" +
          this.mod_JobId +
          "/",
        header: config.headers,
        data: formData,
      }).then(() => {
        this.attendDialog = false;
        alert("勤務時間を修正しました");
        this.init(this.keyYM);
      });
    },
    computedDateFormattedMomentjsFromStr(str) {
      return str ? moment(str).format("YYYY-MM-DD HH:mm") : "";
    },
    computedDateFormattedMomentjsFromStrDate(str) {
      return str ? moment(str).format("YYYY-MM-DD") : "";
    },
    computedDateFormattedMomentjsFromStrTime(str) {
      return str ? moment(str).format("HH:mm") : "";
    },
    getHHMM(minutes) {
      if (minutes != "") {
        let h = this.omission(minutes / 60);
        let m = minutes % 60;
        if (String(h).length == 1) {
          if (String(h) == "0" && minutes < 0) {
            h = "-00";
          } else {
            h = String(h).padStart(2, "0");
          }
        }
        m = String(m).padStart(2, "0");
        if (String(m).charAt(0) === "-") {
          m = m.substr(1, 2).padStart(2, "0");
        }
        return h + ":" + m;
      } else {
        return minutes;
      }
    },
    omission(Num) {
      if (Num >= 0) {
        return Math.floor(Num);
      } else {
        return Math.ceil(Num);
      }
    },
    getWeekday(strdate) {
      const weekday = ["日", "月", "火", "水", "木", "金", "土"];
      return weekday[moment(strdate).day()];
    },
    dateFormat(datestr) {
      if (datestr) {
        return moment(datestr).format("YYYY-MM-DD HH:mm");
      } else {
        return null;
      }
    },
    calWorkTime(in_time, out_time, break_time) {
      if (in_time && out_time && break_time != null) {
        let diff = moment(out_time)
          .startOf("minute")
          .diff(moment(in_time).startOf("minute"), "minutes");
        diff = diff - break_time;
        return diff;
      } else {
        return null;
      }
    },
    setJobName(jobid) {
      api.get(this.host + "/api/jobs/" + jobid).then((response) => {
        this.mod_JobName = response.data.job_name;
      });
    },
    getUsername() {
      api
        .get(this.host + "/api/username/" + this.targetUser)
        .then((response) => {
          this.username = response.data[0].full_name;
        });
    },
    editAttendItem(item) {
      if (item.id) {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.mod_JobName = null;
        this.mod_JobId = null;
        this.mod_date = null;
        this.mod_in_date = null;
        this.mod_out_date = null;
        this.mod_in_time = null;
        this.mod_out_time = null;
        this.mod_BreakTime = null;
        if (item.jobid) {
          this.setJobName(item.jobid);
          this.mod_JobId = item.jobid;
        }
        if (item.in_time) {
          this.mod_in_date = moment(item.in_time).format("YYYY-MM-DD");
          this.mod_in_time = moment(item.in_time).format("HH:mm");
        }
        if (item.out_time) {
          this.mod_out_date = moment(item.out_time).format("YYYY-MM-DD");
          this.mod_out_time = moment(item.out_time).format("HH:mm");
        }
        if (item.break_time) {
          this.mod_BreakTime = item.break_time;
        }
        this.mod_date = item.date;
        this.attendDialog = true;
      }
    },
    addAttendItem(item) {
      this.job_data = this.getJobList(this.userinfo.username, this.keyYM);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.attendAddDialog = true;
      if (this.$refs.addform) {
        this.$refs.addform.resetValidation();
      }
      this.add_date = item.date;
      this.add_in_date = item.date;
      this.add_out_date = item.date;
    },
    getJobList(user, targetYM) {
      api
        .get(this.host + "/api/joinedjob/" + user + "/" + targetYM)
        .then((response) => {
          this.job_data = response.data;
        });
    },
    delAttendItem(pk) {
      let delflg = confirm("勤務状況を削除します");
      if (pk && delflg) {
        api({
          method: "delete",
          url: this.host + "/api/attenddel/" + pk,
          header: { accept: "application/json" },
        }).then(() => {
          alert("勤務を削除しました");
          this.init(this.keyYM);
        });
      }
    },
    delExItem(pk) {
      if (pk) {
        api({
          method: "delete",
          url: this.host + "/api/exdel/" + pk,
          header: { accept: "application/json" },
        }).then(() => {
          alert("勤務を削除しました");
          this.init(this.keyYM);
        });
      }
    },
    loadItem() {
      api
        .get(
          this.host +
            "/api/attendance/list/" +
            this.targetUser +
            "/" +
            this.keyYM
        )
        .then((response) => {
          // alert(response.data.length);
          let output = [];
          let totalminute = 0;
          this.monthData = this.createMonthData(this.keyYM);
          for (let loopobj of this.monthData) {
            let target = response.data.filter((obj) => {
              return obj.date === loopobj.date;
            });
            if (target.length > 0) {
              for (let i = 0; i < target.length; i++) {
                let rtnobj = {};
                rtnobj["date"] = loopobj.date;
                rtnobj["wday"] = loopobj.wday;
                rtnobj["hday"] = loopobj.hday;
                rtnobj["in_time"] = target[i].in_time;
                rtnobj["out_time"] = target[i].out_time;
                rtnobj["break_time"] = target[i].break_time;
                rtnobj["jobid"] = target[i].jobid;
                rtnobj["jobname"] = target[i].jobname;
                rtnobj["approve1"] = target[i].approve1;
                rtnobj["approve2"] = target[i].approve2;
                rtnobj["id"] = target[i].id;

                let diff = this.calWorkTime(
                  target[i].in_time,
                  target[i].out_time,
                  target[i].break_time
                );
                rtnobj["work_time"] = this.getHHMM(diff);
                totalminute += diff;

                output.push(rtnobj);
              }
            } else {
              let rtnobj = {};
              rtnobj["date"] = loopobj.date;
              rtnobj["wday"] = loopobj.wday;
              rtnobj["hday"] = loopobj.hday;
              output.push(rtnobj);
            }
          }
          this.total = this.getHHMM(totalminute);
          this.items = output;
        });
    },
    loadEItem() {
      this.etotal = 0;
      api
        .get(this.host + "/api/expenses/" + this.targetUser + "/" + this.keyYM)
        .then((response) => {
          this.eitems = response.data;
          for (let ei of this.eitems) {
            if (ei.status_disp === "最終承認") {
              this.etotal += ei.amount;
            }
          }
        });
    },
    createMonthData: function (target) {
      const weekday = ["日", "月", "火", "水", "木", "金", "土"];
      let tagetDate = moment(target);
      const daysInMonth = tagetDate.daysInMonth();
      //返却配列
      let dateArray = [];
      //ループ回す前に1日減算
      tagetDate.subtract(1, "d");
      for (let i = 0; i < daysInMonth; i++) {
        let dateObj = {};
        tagetDate.add(1, "d");
        dateObj["date"] = tagetDate.format("YYYY-MM-DD");
        let wdayIndex = tagetDate.day();
        dateObj["wday"] = weekday[wdayIndex];
        if (
          wdayIndex == 0 ||
          wdayIndex == 6 ||
          JapaneseHolidays.isHoliday(tagetDate.toDate())
        ) {
          dateObj["hday"] = "休日";
        } else {
          dateObj["hday"] = "";
        }
        dateArray.push(dateObj);
      }
      return dateArray;
    },
  },
};
</script>

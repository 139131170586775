<template>
  <v-card flat tile>
    <v-toolbar color="primary" dark flat tile>
      <v-btn icon to="/">
        <v-icon>mdi-home-circle</v-icon>
      </v-btn>
      <v-toolbar-title class="px-2">勤怠経費管理システム</v-toolbar-title>
      <v-spacer v-if="$route.meta.requiresAuth"></v-spacer>
      <v-toolbar-items v-if="$route.meta.requiresAuth">
        <v-btn
          icon
          href="http://help.noar.biz.s3-website-ap-northeast-1.amazonaws.com/"
          target="_blank"
          rel="opener"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
        <v-btn icon to="/changepassword">
          <v-icon>mdi-key-change</v-icon>
        </v-btn>
        <v-btn icon to="/create">
          <v-icon>mdi-database-plus</v-icon>
        </v-btn>
        <v-btn icon @click.prevent="clickLogout">
          <v-icon @click.prevent="clickLogout">mdi-logout</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  computed: {
    isLoggedIn: function() {
      return this.$store.getters["auth/isLoggedIn"];
    }
  },
  methods: {
    // ログアウトリンク押下
    clickLogout: function() {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("message/setInfoMessage", {
        message: "ログアウトしました。"
      });
      this.$router.replace("/login");
    },
    // ログインリンク押下
    clickLogin: function() {
      this.$store.dispatch("message/clearMessages");
      this.$router.replace("/login");
    }
  }
};
</script>